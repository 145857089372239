@menu-bg-color: #f3f4f7;
@header-top-height: 90px;
@menu-item-color: #0d0d0d;

header {
  color: #ffffff;
  font-family: Roboto, Sans-Serif;
  font-size: 16px;
  font-weight: 300;
  height: 140px;
  .navbar {
    background: #f3f4f7;
    .container {
      max-width: 1700px;
      width: 92%;
    }
  }
  .navbar-header {
    width: 100%;
    position: relative;
    z-index: 1;
    height: @header-top-height;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: -50vw;
      background: url(/frontend/web/images/itarian/top-bg.png) repeat-y top left;
      background-size: contain;
      width: 200vw;
      height: @header-top-height;
      z-index: -1;
    }
    .navbar-toggle {
      position: absolute;
      right: 0;
      margin-top: 18px;
      .icon-bar {
        background-color: #fff;
        margin: 6px 0;
        width: 30px;
        height: 3px;
      }
    }

    .navbar-brand {
      line-height: @header-top-height;
      padding-top: 0;
      padding-bottom: 0;
      vertical-align: middle;
    }
    .contact-us {
      float: right;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: @header-top-height;
      margin-top: 12px;
      &__text {
        position: relative;
        font-size: 20px;
        font-weight: 500;
        &:before {
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          background: url(/frontend/web/images/itarian/email-icon.svg) no-repeat center center;
          background-size: cover;
          top: 3px;
          left: -30px;
        }
      }
      &__email {
        a {
          color: #ffffff;
          font-size: 16px;
          font-weight: 500;
        }
      }

    }

  }
  .navbar-collapse {
    position: relative;
    z-index: 0;
    color: #ffffff;
    #cssmenu {
      color: @menu-item-color;

      .menu-top-level-item {
        padding: 10px 10px;
        text-transform: uppercase;
        font-weight: 400;
        color: @menu-item-color;
        &:hover {
          color: #192a6f;
          //&:before{
          //  content: "";
          //  position: absolute;
          //  bottom: 0;
          //  left: 0;
          //  height: 2px;
          //  width: 100%;
          //  background: #192a6f;
          //}
        }
        @media screen and (max-width: 1260px) {
          //padding: 10px 7px;
        }

      }

      .nav {
        @media screen and (max-width: 1200px) {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          padding-left: auto;
          font-size: 15px;
        }
        &.navbar-nav {
          background: @menu-bg-color;
          position: relative;
          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: -15px;
            width: 100vw;
            height: 100%;
            background: @menu-bg-color;
          }
        }
        &.navbar-right {
          font-size: 14px;
          @media screen and (max-width: 1200px) {
            justify-content: flex-start;
            width: 100%;
          }
        }
        ul {
          list-style: none;
          padding: 0;
        }
        .inner-menu {
          //background: #fff;
          border-top: 2px solid #e3e3e3;
          //border-bottom: 1px solid #e3e3e3;
          left: 0;
          right: 0;
          position: absolute;
          display: none;
          z-index: 1000;
          .has-sub {
            float: left;
          }
          .menu-title {
            padding: 0 10px 0 0;
            font-weight: 400;
          }
          li {
            a {
              color: @menu-item-color;
            }
          }
        }

        > li {
          &:hover {
            > a {
              &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100%;
                background: #192a6f;
              }
            }
            .inner-menu {
              min-width: 100vw;
              display: block;

              > ul {
                display: inline-block;
                width: auto;
                background: @menu-bg-color;
                padding: 12px 15px 10px 15px
              }

            }
          }
        }

        .menu-item-level-1,
        .menu-item-level-2 {
          padding: 7px 15px 0 0;
          li {
            margin: 10px 0;
          }
        }
      }
    }

  }

}

@media screen and (max-width: 1200px) {

}


@media screen and (max-width: 768px) {
  .navbar-fixed-top .navbar-collapse {
    max-height: initial;
  }

  header .navbar-collapse #cssmenu .nav {
    display: flex;
    flex-direction: column;
    margin: 7.5px 0;
  }

  header .navbar-collapse #cssmenu .nav ul,
  header .navbar-collapse #cssmenu .nav ul li {
    display: block;
    width: 100%;
  }

  header .navbar-collapse #cssmenu .nav .menu-item-level-2 {
    padding-left: 20px;
  }

  header .navbar-collapse #cssmenu .nav .inner-menu {
    position: relative;
  }

  header .navbar-collapse #cssmenu .nav.navbar-right {
    width: 100%;
  }

  header .navbar-collapse #cssmenu .nav.navbar-nav:before {
    width: 100%;
  }

  header .navbar-collapse #cssmenu .nav > li:hover > a:before {
    background-color: transparent;
  }

  header .navbar-collapse #cssmenu .nav > li:hover .inner-menu {
    min-width: auto;
  }

  header .navbar-header .contact-us {
    display: none;
  }
}
